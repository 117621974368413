export const resolution = '250';
export const color = '#666';
export const googleDrive = 'https://drive.google.com/uc?id=';

export const portfolioData = {
  game: [
    {
      title: 'Ransacked',
      team: 'Real Nifty Games',
      image: `${googleDrive}1PskzEOFzZgCYNaPFRwg6GslQd7EHUthp`,
      alt: 'A thief',
      description:
        'Ransacked is a third person stealth thieving game, where you can steal almost anything.  Built in Unreal Engine 4 for my second workshop class, I primarily advanced my knowledge by crafting AI for the guards.  The many states of this AI include patrolling, chasing, searching (after initially losing sight of player), and replacing lost items.  Huge shoutout to the art team for their vision and style on this game.',
    },
    {
      title: 'Madhouse',
      team: 'Goliath Games',
      image: `${googleDrive}1y51ZSlqlbRgkqrpswhs2fG4zlcwETZRh`,
      alt: 'A spooky thing',
      description:
        'Madhouse is a psychological thriller made in Unreal Engine 4, based around investigating an abandoned asylum.  This was made in my first workshop class at UCF, and was my first experience using UE4.  I utilized blueprints to implement UI, sound, puzzles and other miscellaneous gameplay events.  Another shoutout to the art team for their incredible talent.',
    },
    {
      title: '2DArena',
      team: 'Lucubration Studio Design',
      image: `${googleDrive}13rNVN_iFy0XPj-yx7Er7fq2MxKv7QKe0`,
      alt: 'Image during development, showing AI chasing player',
      description: `2DArena is a game I'm building in my free time.  I'm using it as a creative outlet, and a way to continue learning and integrating technology.  My goal with this project is to create a browser game with authentication and statistics.  I'm also organizing friends who would like to participate in my creative process, to work towards tangible goals.`,
    },
  ],
};

export const musicData = {
  song: [
    {
      title: 'DMR',
      link:
        'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/300368128&amp;color=ff5500&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false',
    },
    {
      title: 'Mistakes Were Made',
      link:
        'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/120588766&amp;color=ff5500&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false',
    },
    {
      title: 'Malevolent Dreams',
      link:
        'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/654128090%3Fsecret_token%3Ds-b7N6z&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true',
    },
    {
      title: 'Phobia',
      link:
        'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/654122654%3Fsecret_token%3Ds-pgR7l&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true',
    },
    {
      title: 'Megadeth Riff',
      link:
        'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/654127658%3Fsecret_token%3Ds-uYiWS&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true',
    },
    {
      title: 'Death Follows',
      link:
        'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/654128453%3Fsecret_token%3Ds-Qd1nW&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true',
    },
  ],
};

export const alexData = {
  image: `${googleDrive}1FzVWS2SPvMNKFOhcmFMwKNqWiEmFBCM8`,
  github: 'https://github.com/alexsimonson',
};
export const resumeData = {
  programmingLanguages: [
    {
      language: 'PHP/Laravel',
    },
    {
      language: 'Javascript/React/React-Native/Ajax/VueJs/jQuery',
    },
    {
      language: 'Graphql/PostgreSQL/pgAdmin/MySQL/phpMyAdmin',
    },
    {
      language: 'HTML/CSS/Bootstrap 3&4/Flexbox/',
    },
    {
      language: 'Python',
    },
    {
      language: 'C/C#/C++',
    },
  ],
  familiarTechnologies: [
    {
      technologies: 'AWS/Google App Engine/Fortrabbit',
    },
    {
      technologies: 'Github/Bitbucket/Jira/Beanstalk/Asana',
    },
    {
      technologies: 'KnexJS & Laravel Migrations',
    },
    {
      technologies: 'Node/NPM/Composer/SSH',
    },
  ],
  tools: [
    {
      tool: 'Photoshop/Gimp/Affinity',
    },
    {
      tool: 'Studio One',
    },
    {
      tool: 'Unity 5/Unreal Engine 4',
    },
  ],
  workExperience: [
    {
      company: 'Launcher Solutions',
      title: 'PHP Application Developer',
      startDate: '2020-03-06',
      endDate: 'null',
    },
    {
      company: 'Nucamp',
      title: 'Coding Instructor',
      startDate: '2020-12-14',
      endDate: 'null',
    },
    {
      company: 'Cortical Interactive',
      title: 'Software Engineer',
      startDate: '2019-09-02',
      endDate: '2019-12-01',
    },
    {
      company: 'Step Research',
      title: 'Software Developer',
      startDate: '2019-04-15',
      endDate: '2019-07-17',
    },
    {
      company: 'The Right Profile',
      title: 'Junior Software Developer',
      startDate: '2018-03-05',
      endDate: '2019-04-01',
    },
    {
      company: 'Captel Service Specialists',
      title: 'Captioning Assistant',
      startDate: '2016-05-09',
      endDate: '2018-02-15',
    },
    {
      company: 'Vesta',
      title: 'Facility Attendant',
      startDate: '2015-05-20',
      endDate: '2015-08-10',
    },
    {
      company: 'Vesta',
      title: 'Head Lifeguard',
      startDate: '2010-05-01',
      endDate: '2014-09-05',
    },
  ],
  education: [
    {
      degree: 'Bachelor of Arts',
      major: 'Digital Media: Game Design',
      minor: 'Computer Science',
    },
  ],
};

export const recipes = {};
