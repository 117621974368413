import React from 'react';
import ApolloClientInfo from './ApolloClientInfo';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Switch, Route, Link, useParams } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { Loading, Error } from './DefaultWarnings';
import './App.css';

// gain access to the apollo client
const { client } = ApolloClientInfo;
let subRecipes = 1;

export const ALL_RECIPE_FIELDS = gql`
  fragment allRecipeFields on Recipe {
    id
    name
    description
    prepTime
    cookTime
    ingredients {
      recipeId
      name
      amount
      subRecipe
    }
    directions {
      recipeId
      subRecipe
      stepNumber
      instruction
    }
  }
`;

export const queryGetRecipes = gql`
  {
    recipes {
      ...allRecipeFields
    }
  }
  ${ALL_RECIPE_FIELDS}
`;

export const queryGetRecipe = gql`
  query recipe($recipeId: Int) {
    recipe(recipeId: $recipeId) {
      ...allRecipeFields
    }
  }
  ${ALL_RECIPE_FIELDS}
`;

export const getRecipes = () => {
  console.log('test');
  return client.query({ query: queryGetRecipes, fetchPolicy: 'network-only' });
};

export const IngredientsList = data => {
  const { recipe } = data;
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Ingredient</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        {recipe.ingredients !== null &&
          recipe.ingredients.map((ingredient, key) => {
            // console.log('ingredient', ingredient);
            if (ingredient !== null && ingredient !== undefined) {
              // might be a better idea to put this into a table
              return (
                <tr key={key}>
                  <td>{ingredient.name}</td>
                  <td>{ingredient.amount}</td>
                </tr>
              );
            }
            return false;
          })}
      </tbody>
    </Table>
  );
};

export const DirectionList = data => {
  const { recipe } = data;
  // pushing each list into meHoldLists, then returning that after the for loop
  let meHoldLists = [];
  for (let i = 1; i <= subRecipes; i++) {
    meHoldLists.push(
      <div key={`subRecipe${i}`}>
        <ol>
          {recipe.directions !== null &&
            recipe.directions.map((direction, key) => {
              if (direction !== null && direction !== undefined) {
                if (direction.subRecipe === i && direction.stepNumber === 0) {
                  // this is a title
                  return <h3 key={key}>{direction.instruction}</h3>;
                } else if (direction.subRecipe === i) {
                  return <li key={key}>{direction.instruction}</li>;
                } else return false;
              } else return false;
            })}
        </ol>
        <br></br>
      </div>
    );
  }
  return <div>{meHoldLists}</div>;
};

export const Recipe = () => {
  let { recipeId } = useParams();
  recipeId = parseInt(recipeId);

  const { data, loading, error } = useQuery(queryGetRecipe, {
    variables: { recipeId }
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  if (data) {
    // console.log('data!', data);
    const { recipe } = data;

    // figure out how many subrecipes there are
    // Note: Provided that data is setup correctly (which it should be), number of subRecipes can be found by accessing the last index
    if (recipe.directions !== null) {
      recipe.directions.map((direction, key) => {
        if (direction !== null && direction !== undefined) {
          if (direction.subRecipe !== subRecipes) {
            subRecipes++;
            return true;
          } else return false;
        } else return false;
      });
    }

    return (
      <div>
        <h1>{recipe.name}</h1>
        <br></br>
        <h2>Description</h2>
        <p>{recipe.description}</p>
        <br></br>
        <h2>Ingredients</h2>
        <IngredientsList recipe={recipe} />
        <br></br>
        <h2>Directions</h2>
        <br></br>
        <DirectionList recipe={recipe} />
      </div>
    );
  }
  return false;
};

export const AvailableRecipes = () => {
  const { data, loading, error } = useQuery(queryGetRecipes);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  if (data) {
    const { recipes } = data;
    // console.log('recipes', recipes);
    return (
      <div>
        <h1>Select a Recipe</h1>
        <br></br>
        {recipes.map((item, key) => {
          return (
            <div key={key}>
              <Link to={`/recipe/${item.id}`}>
                <h3>{item.name}</h3>
              </Link>
              <br></br>
            </div>
          );
        })}
      </div>
    );
  }
  return false;
};

export const Recipes = () => {
  return (
    <div className='App'>
      <header className='App-header'>
        <Switch>
          <Route exact path='/recipe'>
            <AvailableRecipes />
          </Route>
          <Route path={`/recipe/:recipeId`}>
            <Recipe />
          </Route>
        </Switch>
      </header>
    </div>
  );
};

export default Recipes;
