import React from 'react';
import './App.css';
import App from './Route';

export const MusicItem = ({ song }) => {
  const { title, link } = song;
  return (
    <div>
      <h2>{title}</h2>
      <div style={{ display: 'flex', flex: 1 }}>
        <iframe
          title={title}
          width='100%'
          height='166'
          scrolling='no'
          frameBorder='no'
          allow='autoplay'
          src={link}
        ></iframe>
      </div>
    </div>
  );
};

export const PortfolioItem = ({ item }) => {
  const { image, title, team, description, alt } = item;
  return (
    <div style={{ flex: 1 }}>
      <img src={image} alt={alt} className='Portfolio-image' />
      <h2 className='App-text'>{title}</h2>
      <h3 className='App-text'>{team}</h3>
      <p className='App-text'>{description}</p>
    </div>
  );
};
