import React from 'react';

export const Loading = () => {
  return (
    <div className='App'>
      <header className='App-header'>
        <h1>Loading...</h1>
      </header>
    </div>
  );
};

export const Error = data => {
  const { error } = data;
  console.log('error!', error);
  return (
    <div className='App'>
      <header className='App-header'>
        <h1>{error.message}</h1>
      </header>
    </div>
  );
};
