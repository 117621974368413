import React from 'react';
import './App.css';
import './Resume.css';

export const Resume = () => {
  return (
    <div className='App'>
      <Technologies />
      <WorkExperience />
      <Education />
    </div>
  );
};

export const Technologies = () => {
  return (
    <div>
      <h2>
        <u>Familiar Web Stacks, Tools & Technologies</u>
      </h2>
      <ul className='App-ul-tech'>
        <li>Javascript, ES6</li>
        <ul>
          <li>jQuery</li>
          <li>AJAX</li>
          <li>Node</li>
          <li>React</li>
          <li>React-Native</li>
        </ul>
        <li>Apollo GraphQL</li>
        <li>Postgres</li>
        <li>MongoDB</li>
        <li>SQL</li>
        <li>HTML</li>
        <li>CSS</li>
        <ul>
          <li>Flexbox</li>
          <li>Bootstrap 3 & 4</li>
        </ul>
        <li>Google App Engine</li>
        <li>Amazon Web Services</li>
        <li>Firebase</li>
        <li>Heroku</li>
        <li>Git</li>
        <li>SVN</li>
        <li>Beanstalk</li>
        <li>Bitbucket</li>
        <li>Github</li>
      </ul>
    </div>
  );
};

export const Education = () => {
  return (
    <div>
      <h2>
        <u>Education</u>
      </h2>
      <ul>
        <li>Bachelor of Arts, University of Central Florida</li>
        <ul>
          <li>Major: Digital Media Game Design</li>
          <li>Minor: Computer Science</li>
        </ul>
      </ul>
    </div>
  );
};

export const WorkExperience = () => {
  return (
    <div>
      <h2>
        <u>Work Experience</u>
      </h2>
      <h3>PHP Application Developer for Launcher Solutions on Apptraker</h3>
      <h4>March 2020 - Current</h4>
      <ul>
        <li>Maintained, and added features to their REST API</li>
        <li>New Communication Suite built upon Websockets</li>
        <li>
          Admin Deployment tool, allows data to be pushed from one environment
          to another. Designed to be configurable, allowing for many use cases
          throughout the site.
        </li>
      </ul>
      <h3>Full Stack Software Engineer for Cortical Interactive on Ollo</h3>
      <h4>September 2019 - December 2019</h4>
      <ul>
        <li>
          Worked on multiple facets of a mobile dating application using
          React-Native.
        </li>
        <li>
          Worked with location based APIs, to provide a unique, and relevant
          user experience based around lifestyle.
        </li>
        <li>React-Native, Apollo Client for GraphQL</li>
        <li>GraphQL, Postgres, Firebase, Foursquare's API</li>
      </ul>
      <h3>
        Full Stack Developer for Step Research on New Product Infrastructure
      </h3>
      <h4>April 2019 - July 2019</h4>
      <ul>
        <li>
          Setup backend structure of laravel application, including migrations,
          relationships, and integrating them with laravel's nova resources.
        </li>
        <li>DevOps web server management and security</li>
        <li>Unit testing with PHPUnit</li>
        <li>
          Assisted in the development strategies for promoting the application
        </li>
        <li>Laravel, PHP, VueJS, MySQL, phpMyAdmin, Beanstalk, Basecamp</li>
      </ul>
      <h3>Full Stack Developer for The Right Profile on Psychology Software</h3>
      <h4>March 2018 - April 2019</h4>
      <ul>
        <li>Web based psychology software</li>
        <li>Clients ranged from professional athletic teams to law firms</li>
        <li>
          Primarily focused on backend development. SQL and PHP code utilizing
          various APIs.
        </li>
        <li>
          Lead developer on crowdfunding project. Built crowdfunding platform
          from scratch to aid our clients in affording our software.
        </li>
        <li>PHP, jQuery, Bootstrap 3 and 4, mySQL, phpMyAdmin</li>
      </ul>
      <h3>AI Programmer for Real Nifty Games on Ransacked</h3>
      <h4>January 2018 - May 2018</h4>
      <ul>
        <li>A thieving game where you can steal nearly anything</li>
        <li>Guard AI States: Patrol, Chase, Search, Arrest, Return</li>
        <li>Unreal Engine 4, Blueprints, Tortoise SVN</li>
      </ul>
      <h3>Lead Programmer for Goliath Games on Madhouse</h3>
      <h4>August 2017 - December 2017</h4>
      <ul>
        <li>
          Implemented core gameplay mechanics, including movement, flashlight,
          and checkpoint system
        </li>
        <li>Created and implemented frogger puzzle and light puzzle</li>
      </ul>
    </div>
  );
};

export default Resume;
