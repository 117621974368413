import React from 'react';
import './App.css';

import { PortfolioItem, MusicItem } from './PortfolioView';
import { portfolioData, musicData } from './data';

export const GamePortfolio = () => {
  return portfolioData.game.map((item, index) => (
    <PortfolioItem key={index} item={item} />
  ));
};

export const MusicPortfolio = () => {
  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      {musicData.song.map((item, index) => (
        <div key={index}>
          <MusicItem key={index} song={item} />
          <br></br>
        </div>
      ))}
    </div>
  );
};

// layout for portfolio route on site
export const PortfolioView = () => {
  return (
    <div style={{ flex: 1 }}>
      <h2 style={{ marginLeft: 10, textAlign: 'center' }}>Game Development</h2>
      <GamePortfolio />
      <br></br>
      <h2 style={{ marginLeft: 10, textAlign: 'center' }}>
        Music I've Recorded
      </h2>
      <p className='App-text'>
        My songs are always evolving, but I'm working towards an album or two as
        a goal. Here are some things I've dubbed "complete" for now. Have a
        listen if you'd like!
      </p>
      <MusicPortfolio />
    </div>
  );
};
