import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Home';
import { PortfolioView } from './Portfolio';
import About from './About';
import Resume from './Resume';
import Recipe from './Recipe';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import ApolloClientInfo from './ApolloClientInfo';

const App = () => {
  const { client } = ApolloClientInfo;
  return (
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>
        <Router>
          <div className='App'>
            <div className='App-navContainer'>
              <nav className='App-navbar'>
                <ul className='App-ul'>
                  <li className='Nav-li'>
                    <Link className='App-link' to='/'>
                      Home
                    </Link>
                  </li>
                  <li className='Nav-li'>
                    <Link className='App-link' to='/about'>
                      About
                    </Link>
                  </li>
                  <li className='Nav-li'>
                    <Link className='App-link' to='/portfolio'>
                      Portfolio
                    </Link>
                  </li>
                  <li className='Nav-li'>
                    <Link className='App-link' to='/resume'>
                      Resume
                    </Link>
                  </li>
                  {/* <li className='Nav-li'>
                    <Link className='App-link' to='/recipe'>
                      Recipes
                    </Link>
                  </li> */}
                </ul>
              </nav>
            </div>
            <div className='App-pageContainer'>
              <Switch>
                <Route exact path='/' component={Home} />
                <Route path='/portfolio' component={PortfolioView} />
                <Route path='/about' component={About} />
                <Route path='/resume' component={Resume} />
                <Route path='/recipe' component={Recipe} />
              </Switch>
            </div>
          </div>
        </Router>
      </ApolloHooksProvider>
    </ApolloProvider>
  );
};

export default App;
