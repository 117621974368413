import React from 'react';
import './App.css';
import ImageRound from 'react-rounded-image';
import { Link } from 'react-router-dom';
import { alexData, resolution } from './data';

const Home = () => {
  return (
    <div className='App'>
      <header className='App-header'>
        <ImageRound
          image={alexData.image}
          roundedColor='#666'
          imageWidth={resolution}
          imageHeight={resolution}
          roundedSize='13'
        />
        <h1 className='App-text'>Yo I'm Alex</h1>
        <ul className='Home-ul'>
          <li className='Nav-li'>
            <Link to='/about' className='App-link'>
              About
            </Link>
          </li>
          <li className='Nav-li'>
            <Link to='/portfolio' className='App-link'>
              Portfolio
            </Link>
          </li>
          <li className='Nav-li'>
            <Link to='/resume' className='App-link'>
              Resume
            </Link>
          </li>
          <li className='Nav-li'>
            <a
              className='App-href'
              href={alexData.github}
              rel='noopener noreferrer'
              target='_blank'
            >
              Github
            </a>
          </li>
        </ul>
      </header>
    </div>
  );
};

export default Home;
