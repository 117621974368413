import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    console.log('graphQLErrors', graphQLErrors);
  }
  if (networkError) {
    console.log('networkError', networkError);
  }
});

//graphql API endpoint
const httpLink = new HttpLink({
  uri: 'https://heroku-apollo-ap-server.herokuapp.com/'
});

// local url: http://localhost:4000/
// private url: http://192.168.0.15:4000/
// public url: https://heroku-apollo-ap-server.herokuapp.com/

class ApolloClientInfo {
  constructor() {
    this.client = new ApolloClient({
      link: ApolloLink.from([errorLink, httpLink]),
      cache: new InMemoryCache()
    });
  }
}

export default new ApolloClientInfo();
