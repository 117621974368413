import React from 'react';
import ImageRound from 'react-rounded-image';
import { alexData, resolution, color } from './data';
import './App.css';

export const About = () => {
  return (
    <div className='App'>
      <header className='App-header'>
        <ImageRound
          image={alexData.image}
          roundedColor={color}
          imageWidth={resolution}
          imageHeight={resolution}
          roundedSize='13'
        />
        <br></br>
        <div className='App-aboutText'>
          <p className='App-text'>
            I'm a graduate of the University of Central Florida, and received a
            BA in Game Design with a minor in Computer Science. During my time
            at UCF, I evolved my emerging developer skills while working with
            teams on complex game projects. Having a stronger programming
            background than most at the time, I focused my time on learning
            Unity to implement mechanics and game logic that could be harnassed
            by level designers, and used to show off the artist's work.
          </p>
          <br></br>
          <p className='App-text'>
            In the last five years, I set out to become a self sufficient
            developer. I have a lot of interests that I've experimented with
            over the years, and web development being one of them is where I've
            found myself. I've exposed myself to many different technology
            stacks, and mastered how to utilize what I know to hack away at what
            I don't. I try to stay busy with many different projects, and tend
            to set far too ambitious of goals for myself. Recently I've been at
            work on a web app built on the PERN stack (Postgres, Express, React,
            Node). My goal with this project is to have a core building block
            that will speed up prototyping for future projects.
          </p>
          <br></br>
          <p className='App-text'>
            Since graduating, I've worked as a full stack web developer for
            several different startups/small companies. The applications I've
            worked on vary from psychology, to dating and the auto industry.
            I've had the privilege to learn a lot about myself, other people,
            and how personalities interact at the psychology company. I enjoyed
            gaining insight into how others might perceive the world, and why.
            While I always felt this way, this is when I learned that my brain's
            strength is being "super-analytical", a term which comes from their
            analysis. So far all of these companies have had less than ten
            staff. I compounded my knowledge of how the web works, and I've
            gained perspective into how different technology stacks can be
            leveraged and the business decisions behind them. Most importantly,
            I've been able to observe my leaders, taking from their process and
            augmenting mine. I've analyzed what has worked, expressed strong
            analytical feedback to what hasn't, and provided solutions to those
            who would listen.
          </p>

          <br></br>
          <p className='App-text'>
            Speaking of listening, I love making music in my free time. I find
            it's the thing that I'm best at, and get the most enjoyment out of
            naturally. I feel very relaxed when I sit down and play whatever
            comes to mind on the guitar. I appreciate the freedom I've built up
            on the instrument over the last decade. I've managed to pickup
            several instruments over the years, including drums and piano. My
            skills very drastically between them though. I really like coming up
            with song structures in my head, and bringing them to life with my
            musical abilities. I've managed to obtain all the gear I need for my
            own music studio as well. Life is good.
          </p>
        </div>
      </header>
    </div>
  );
};

export default About;
